import { forwardRef } from 'react';

import Box, { boxProps } from 'humanity/primitives/box';
import { theme } from 'utils/theme.v2';

const Container = forwardRef((props, ref) => (
  <Box
    ref={ref}
    sx={{
      my: 0,
      mx: 'auto',
      px: [4, null, 5],
      width: [
        `calc(${theme.sizes.contentWidth}px + ${theme.space[4]}px * 2)`,
        null,
        `calc(${theme.sizes.contentWidth}px + ${theme.space[5]}px * 2)`,
      ],
      maxWidth: '100%',
    }}
    {...props}
  />
));

Container.displayName = 'Container';

Container.propTypes = {
  ...boxProps,
};

export default Container;
